<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      hide-overlay
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <form-header
            :title="title"
            :form="form"
            :permissions="permissions"
            :view="view"
            @viewChange="viewChanged"
            @saveForm="saveForm"
            @disableRecord="change_status"
            @newForm="newForm"
            @close="close"
            @cancelForm="cancelForm"
            @edit="view = 'edit'"
            :show-enable-button="false"
            :show-custom-button="form.status != 'Active'"
            custom-button-color="success"
            custom-button-text="APPROVE"
            @customButton="change_status('enable')"
          />
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-12">
                      <el-form-item
                        label="Logo"
                        prop="logo_path"
                        style="min-height: 120px"
                      >
                        <upload-field
                          size="mini"
                          folder="exhibitors_logo"
                          allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                          :width="100"
                          :height="100"
                          :disabled="view != 'new' && view != 'edit'"
                          v-model="form.logo_path"
                        />
                      </el-form-item>
                      <el-form-item
                        label="Image"
                        prop="image_path"
                        style="min-height: 120px"
                      >
                        <upload-field
                          size="mini"
                          folder="exhibitor_images"
                          allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                          :width="100"
                          :height="100"
                          :disabled="view != 'new' && view != 'edit'"
                          v-model="form.image_path"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-lg-9 col-sm-12">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12" v-if="view == 'view'">
                          <el-form-item label="Event" prop="events_id">
                            <el-input
                              size="mini"
                              :disabled="true"
                              v-model="form.event"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <el-form-item label="Exhibitor Name" prop="name">
                            <el-input
                              size="mini"
                              :disabled="view != 'new' && view != 'edit'"
                              v-model="form.name"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <el-form-item label="Tel" prop="tel">
                            <el-input
                              size="mini"
                              :disabled="view != 'new' && view != 'edit'"
                              v-model="form.tel"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <el-form-item label="Mobile" prop="mobile">
                            <el-input
                              size="mini"
                              :disabled="view != 'new' && view != 'edit'"
                              v-model="form.mobile"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <el-form-item label="Email" prop="email">
                            <el-input
                              size="mini"
                              :disabled="view != 'new' && view != 'edit'"
                              v-model="form.email"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <el-form-item label="Address" prop="address">
                            <el-input
                              size="mini"
                              type="textarea"
                              :rows="4"
                              :disabled="view != 'new' && view != 'edit'"
                              v-model="form.address"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <el-form-item label="Stall No" prop="stall_no">
                            <el-input
                              size="mini"
                              :disabled="view != 'new' && view != 'edit'"
                              v-model="form.stall_no"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                          <el-form-item label="Profile" prop="profile">
                            <html-editor v-model="form.profile" />
                          </el-form-item>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <el-form-item label="Status" prop="status">
                            <el-input
                              size="mini"
                              :disabled="true"
                              v-model="form.status"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-card
                :loading="loading"
                class="mx-auto ma-2"
                :elevation="0"
                outlined
              >
                <v-system-bar height="47" color="secondary">
                  <h3 class="white--text">USERS</h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    title
                    color="warning"
                    @click="newUser"
                    class="mr-2 hidden-sm-and-down"
                    elevation="0"
                  >
                    <v-icon color="white">mdi-plus</v-icon>
                    CREATE NEW USER
                  </v-btn>
                </v-system-bar>
                <v-card-text>
                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                      <el-table :data="form.users" style="width: 100%">
                        <el-table-column
                          prop="name"
                          width="150"
                          label="User Name"
                          :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column
                          prop="email"
                          label="Email"
                          :show-overflow-tooltip="true"
                          width="150"
                        ></el-table-column>
                        <el-table-column
                          prop="mobile_no"
                          label="Mobile No"
                          :show-overflow-tooltip="true"
                          width="120"
                        ></el-table-column>
                        <el-table-column
                          prop="designation"
                          label="Designation"
                          :show-overflow-tooltip="true"
                          width="120"
                        ></el-table-column>
                        <el-table-column
                          prop="gender"
                          label="Gender"
                          :show-overflow-tooltip="true"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          prop="age"
                          label="Age"
                          :show-overflow-tooltip="true"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          prop="email_verified"
                          label="Email Verified"
                          :show-overflow-tooltip="true"
                          align="center"
                          width="150"
                        >
                          <template slot-scope="scope">
                            {{
                              colFormatYesNo(
                                "",
                                "",
                                !empty(scope.row.email_verified_at)
                              )
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="status"
                          label="Status"
                          width="100"
                        ></el-table-column>
                        <el-table-column fixed="right" label="..." width="80">
                          <template slot-scope="scope">
                            <v-icon
                              style="cursor: hand"
                              color="success"
                              class="mr-1"
                              @click="viewUser(scope.row)"
                            >
                              mdi-eye
                            </v-icon>
                            <v-icon
                              style="cursor: hand"
                              color="error"
                              @click="resetUser(scope.row)"
                            >
                              mdi-lock-reset
                            </v-icon>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
      <new-user-form
        :show="showNewUserForm"
        :data="selectedUser"
        :exhibitor="form"
        :end_point="end_point"
        @updated="userUpdated"
        @created="userCreated"
        @close="
          selectedUser = null;
          showNewUserForm = false;
        "
      />
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import { Button, Form, FormItem, Input } from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
import FormHeader from "@/components/custom/FormHeader.vue";
import UploadField from "@/components/custom/UploadField.vue";
import htmlEditor from "@/components/custom/htmlEditor.vue";
import newUserForm from "./newUserForm.vue";
import Swal from "sweetalert2";
export default {
  watch: {
    show: function () {
      this.showForm = this.show;
      this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.getRecord();
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    title: {
      type: String,
      default: "",
    },
    end_point: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    FormHeader,
    UploadField,
    htmlEditor,
    newUserForm,
  },
  data() {
    return {
      form: {},
      view: "view",
      rules: {
        name: [
          {
            required: true,
            message: "Please enter User Name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter User Email",
            trigger: "blur",
          },
        ],
        clients_id: [
          { required: true, message: "Please select Client", trigger: "blur" },
        ],
        mobile: [
          {
            required: true,
            message: "Please enter Mobile No",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
      selectedUser: null,
      showNewUserForm: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.api(this.end_point + api, this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.form = response.data;
            this.view = "view";
            this.showInfo("Record Saved successfully");
            this.$emit("updated");
            if (this.popup) this.close();
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: null,
        event: null,
        name: null,
        logo_path: null,
        logo_byte: null,
        address: null,
        tel: null,
        mobile: null,
        email: null,
        stall_no: null,
        profile: null,
        image_path: null,
        image_byte: null,
        users: [],
        status: "Active",
      };
      this.view = "new";
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      this.close();
    },
    viewChanged(val) {
      this.view = val;
    },
    newUser() {
      this.selectedUser = null;
      this.showNewUserForm = true;
    },
    viewUser(user) {
      this.selectedUser = user;
      this.showNewUserForm = true;
    },
    resetUser(user) {
      Swal.fire({
        title: "Reset Password",
        text: "Are you sure you want to reset the User Password, the User will receive an email",
        type: "warning",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        cancelButtonText: "NO",
        confirmButtonText: "YES",
      }).then((result) => {
        if (result.value) {
          this.resetPassword(user);
        }
      });
    },
    resetPassword(user) {
      this.loading = true;
      this.api(this.end_point + "reset-exhibitor-user-password", user)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.showInfo("User Password changed successfully");
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    userUpdated(user) {
      let i = this.arrayGetIndex(this.form.users, this.selectedUser);
      if (i >= 0) {
        this.form.users[i] = user;
      }
    },
    userCreated(user) {
      this.form.users.push(user);
    },
  },
  name: "ExhibitorsForm",
};
</script>
